import { MORALIS_API_Key, MORALIS_CHAIN, MORALIS_URL } from "../ABI/AppParams";


export const getMoralisTokenUsdPrice = (tokenAddress) => {

    return new Promise(async (resolve, reject) => {

        const requestOptions = {
            method: 'GET',
            headers: { 'accept': 'application/json' },
            headers: { 'X-API-Key': MORALIS_API_Key },
            //hasAuth:false
        };

        fetch(`${MORALIS_URL}${tokenAddress}/price?chain=${MORALIS_CHAIN}`,requestOptions)
            .then(response => response.json())
            .then(data => {
                resolve(data);
            }).catch(err => {
                console.error(err);
                reject(err);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
    });

}


export const getWithdrawFeesPerPeriod = (month) => {
    if(month < 4){
        return -1;
    }else if(month < 6){
        return 0.06;
    }else if(month < 9){
        return 0.05;
    }else if(month < 12){
        return 0.04;
    }else{
        return 0;
    }
}
export const getApyPerPeriod = (month) => {
    //let rates = [-1,-1,-1,-1,2.64,4.37,7.1,8.82,10.55,13.28,15.01,16.74,22.46,24.19,25.92];
    let rates = [-1,-1,-1,-1,6.3,8.7,12.2,13.2,14.1,15.9,16.4,16.7,20.7,20.7,20.7];
    if(month >= rates.length){
        return rates[rates.length-1]
    }else{
        return rates[month];
    }
}