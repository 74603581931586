import React from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'


import AdminDashboard from "./pages/AdminDashboard";
import Staking from "./pages/Staking";

function App() {
//todo
  /*const loadData = async () => {
    try {
      const web3 = new Web3(Web3.givenProvider);
      const network = await web3.eth.net.getNetworkType();
      const networkId = await web3.eth.net.getId();
      console.log(networkId, "network", network);
      //dispatch({ type: SET_NETWORK_ID, payload: networkId });
    } catch (e) {
      console.log("Verify providor")
    }
  };*/

  


  


  /*useEffect(() => {
    if (store.account) {
      const web3 = new Web3(Web3.givenProvider);
      const stakingContract = new web3.eth.Contract(
        StackingAbi,
        STAKING_CONTRACT_ADDRESS
      );
      stakingContract.methods.owner().call().then(contractOwner => {
        let isOwner = (contractOwner.toLowerCase() == store.account.toLowerCase() ? true : false);
        console.log("isOwner", isOwner)
        //dispatch({ type: SET_IS_OWNER, payload: isOwner })
      })
    }

  }, [store.account]);
*/
  //if (!ready) return "loading...";
  return (
    <Router>
      <div>
        <div>
          <Navbar />
        </div>
        <Routes>
          <Route exact path='/' element={<div className="main-content">
            <Staking/>
          </div>} />
          <Route exact path='/admin-dashboard' element={<div className="main-content">
            <AdminDashboard  />
          </div>} />
        </Routes>

        <div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
