
import React from 'react';
import StakingTransactionsList from '../components/StakingTransactionsList';

import StakingPool from '../components/StakingPool';
import PoolInfos from '../components/PoolInfos';
import cx from "clsx";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useFadedShadowStyles } from "@mui-treasury/styles/shadow/faded";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#e4ebf6",
    borderRadius: 30,
    minWidth: 256,
    maxWidth: 700,
    marginLeft: "auto",
    marginRight: "auto",
  },
});

export const AdminDashboard = () => {
  const styles = useStyles();
  const shadowStyles = useFadedShadowStyles();

  return (
    <div>
      <Card id="ts-card" className={cx(styles.card)}>
        <div className="card-container">
          <CardContent className="cardContent">
           
          <PoolInfos></PoolInfos>
          <StakingPool></StakingPool>
          <StakingTransactionsList></StakingTransactionsList>


            <div>






            </div>





          </CardContent>


        </div>
      </Card>
    </div>
  );
};

export default AdminDashboard;
