import React, { useEffect } from 'react'
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { balanceVersionState, poolTotalStakedState } from '../atoms/atoms';
import { useRecoilState } from 'recoil'
import { RPC_URL, STAKING_CONTRACT_ADDRESS, TOKEN_NAME } from '../ABI/AppParams';
import { StackingAbi } from '../ABI/StackingAbi';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';

export default function PoolTotalStaked() {
    const { t, i18n } = useTranslation();
    const [balanceVersion, setBalanceVersionState] = useRecoilState(balanceVersionState)
    const [poolTotalStaked, setPoolTotalStaked] = useRecoilState(poolTotalStakedState)

    useEffect(() => {
        const web3 = new Web3(RPC_URL);
        //const web3 = new Web3(window.ethereum)
        const stackedContract = new web3.eth.Contract(
            StackingAbi,
            STAKING_CONTRACT_ADDRESS
        );
        console.log("poolInfo call")
        stackedContract.methods.poolInfo().call().then(poolInfo => {
            console.log("poolInfo",poolInfo)
            let amount = web3.utils.fromWei(poolInfo.swuSupply, "ether");
            setPoolTotalStaked(amount)
        })
    }, [balanceVersion])

    return (
        <div>
        
            <p className="ts-label">
               {t('Pool total')}
               <span className="ts-value"> {poolTotalStaked == '--' ? '--' : parseInt(poolTotalStaked)} <span className="trs ts-value small">{TOKEN_NAME}</span></span>
                {/*<div className="exchange-block">
                    <p className="trs">{TOKEN_NAME}</p>
                    <HorizontalRuleIcon fontSize="large" />
                    <p className="trs">{TOKEN_NAME}</p>
    </div>*/}

            </p>
            </div>
      
    )
}
