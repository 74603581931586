import React, { useEffect, useState } from 'react'
import Web3 from 'web3';
import { STAKED_TOKEN_CONTRACT_ADDRESS_MORALIS, STAKING_CONTRACT_ADDRESS, REWARD_TOKEN_CONTRACT_ADDRESS_MORALIS } from '../ABI/AppParams';
import { StackingAbi } from '../ABI/StackingAbi';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
//import { REFRESH_APY, SET_LPCAKE_USD_PRICE, SET_TRSQ_USD_PRICE } from '../reducers/Main.reducer';
import { getMoralisTokenUsdPrice } from './AppServices';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


function PoolInfos() {
/*
    const [swuSupply, setSwuSupply] = useState(0);
    const [trsqPerBlock, setTrsqPerBlock] = useState(0);

    useEffect(() => {
        getMoralisTokenUsdPrice(STAKED_TOKEN_CONTRACT_ADDRESS_MORALIS).then(dataLpMoralis => {
            getMoralisTokenUsdPrice(REWARD_TOKEN_CONTRACT_ADDRESS_MORALIS).then(dataTRSQMoralis => {
                //dispatch({ type: SET_LPCAKE_USD_PRICE, payload: dataLpMoralis.usdPrice })
                //dispatch({ type: SET_TRSQ_USD_PRICE, payload: dataTRSQMoralis.usdPrice })
                setTimeout(() => {
                    //dispatch({ type: REFRESH_APY })
                }, 3000)

            })
        })
        const web3 = new Web3(Web3.givenProvider);
        const stakingContract = new web3.eth.Contract(
            StackingAbi,
            STAKING_CONTRACT_ADDRESS
        );
        stakingContract.methods.poolInfo().call().then(poolInfos => {
            setSwuSupply(web3.utils.fromWei(poolInfos[3], "ether"));
            console.log("poolInfos", lpSupply, poolInfos)
        })
        stakingContract.methods.TRSQPerBlock().call().then(trsqPerBlockResponse => {
            setTrsqPerBlock(web3.utils.fromWei(trsqPerBlockResponse, "ether"));
        })

        return () => {

        }
    }, [])

    return (
        <>
            <h3 className="titleDash">PoolInfos</h3>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                    <button className="btn-dash btn-large">
                    <h3>Total staked tokens</h3>
                            <h4>{swuSupply}</h4>
                    </button>
                           
                       
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <button className="btn-dash btn-large">
                            <h3>TRSQ/Block</h3>
                            <h4>{trsqPerBlock}</h4>
                        </button>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <button className="btn-dash btn-large">
                            <h3>APY</h3>
                            <h4>{store.apy == '--' ? '--' : parseFloat(store.apy).toLocaleString()} %</h4>
                        </button>
                    </Grid>
                </Grid>
            </Box>
        </>

    )*/
}

export default PoolInfos;
