import React, { useEffect } from 'react'

import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { accountState, balanceVersionState, networkIdState, walletBalanceState } from '../atoms/atoms';
import { useRecoilState } from 'recoil'
import { LPcakeAbi } from '../ABI/LPcakeAbi';
import { AppNetworkId, STAKED_TOKEN_CONTRACT_ADDRESS, TOKEN_NAME } from '../ABI/AppParams';
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';

export default function TokenBalance() {

    const { t, i18n } = useTranslation();

    const [balance, setBalance] = useRecoilState(walletBalanceState)
    const [account, setAccount] = useRecoilState(accountState)
    const [balanceVersion, setBalanceVersionState] = useRecoilState(balanceVersionState)
    const [networkId, setNetworkId] = useRecoilState(networkIdState)

    useEffect(() => {
        if (window.ethereum && account !== '' && account != null && networkId == AppNetworkId) {
            const web3 = new Web3(window.ethereum);
            const tokenContract = new web3.eth.Contract(LPcakeAbi, STAKED_TOKEN_CONTRACT_ADDRESS);
            tokenContract.methods
                .balanceOf(account)
                .call().then(result => {
                    result = web3.utils.fromWei(result, "ether");
                    setBalance(result)
                });
        } else {
            setBalance("--")
        }
    }, [account, balanceVersion, networkId])

    return (

        <div className="w56">
            <p className="ts-label">{t('My balance')}</p>

            <p className="liquidity-value">{balance == "--" ? "--" : parseFloat(balance).toFixed(2)} <span className="trs">{TOKEN_NAME}</span></p>


            {/* <div className="exchange-block">
                        <p className="usdt">BUSD</p>
                        <HorizontalRuleIcon fontSize="large" />
                        <p className="trs">{TOKEN_NAME}</p>
    </div>*/}





        </div>
    )
}
