import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import Box from "@mui/material/Box";
import logoE from "../assets/curs2.png"
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { COMINIG_SOON_ACTIVATED } from "../ABI/AppParams";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.95);
  -webkit-tap-highlight-color: transparent;
`;

export default function DepositDisclaimer(props) {

    const { t, i18n } = useTranslation();
    const [acceptedTerms, setAcceptedTerms] = useState(false)

    useEffect(() => {
        setAcceptedTerms(false);
    }, [props.open])

    return (
        <div>

            <StyledModal
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                id="add-modal"
                open={props.open}
                onClose={props.onClose}
                BackdropComponent={Backdrop}
            >

                {
                    COMINIG_SOON_ACTIVATED ?


                        <Box>
                            <div className="alignWith">
                                <h2 id="modal-title-withdraw"></h2>
                                <button onClick={props.onClose} className="btnClose">x</button>
                            </div>


                            <div className="box-container" style={{ padding: "0px" }}>

                                <div className="modalInp2">
                                    <h5 style={{ textAlign: 'center', paddingBottom: 20 }}><img src={logoE} width="40" style={{ marginRight: 10 }} />
                                    {t('Bientôt disponible')}
                                    </h5>
                                    <h5 style={{ textAlign: 'center', paddingBottom: 20 }}>{t('Ouverture du pool de staking le')}</h5>
                                    <h5 style={{ textAlign: 'center', paddingBottom: 20 }}>01/08/2022</h5>
                                    
                                </div>

                            </div>
                            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                                <button
                                    className="btn-form"
                                    onClick={props.onClose}
                                >
                                    {t('Close')}
                                </button>
                            </div>
                        </Box>

                        :

                        <Box className="ZoomD">
                            <div className="alignWith">
                                <h2 id="modal-title-withdraw"></h2>
                                <button onClick={props.onClose} className="btnClose">x</button>
                            </div>


                            <div className="box-container" style={{ padding: "0px" }}>





                                <div className="modalInp2 zoomM">
                                    <h5 style={{ textAlign: 'center', paddingBottom: 20 }}><img src={logoE} width="40" style={{ marginRight: 10 }} />{t('Staking conditions')}</h5>

                                    <p>{t('The minimum commitment period is 4 months (you will not be able to withdraw your tokens before the end of this period)')}</p>
                                    <p>{t('Each new deposit of tokens initializes the commitment period')}</p>
                                    <p>{t('Yield may vary depending on the evolution of the pool')}</p>
                                    <p>{t('After the end of the minimum commitment period, fees may be deducted from the amount to be withdrawn according to the following rules:')}</p>

                                    <p>{t('-Less than 6 months 6.00%')}</p>
                                    <p>{t('-Less than 9 months 5.00%')}</p>
                                    <p>{t('-Less than 12 months 4.00%')}</p>
                                    <p>{t('-12 months or more 0.00%')}</p>

                                </div>


                                <div className="modalInp2">

                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={acceptedTerms} onChange={e => setAcceptedTerms(!acceptedTerms)} />} label={t("Read and I agree in the conditions")} />
                                    </FormGroup>
                                </div>


                            </div>
                            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                                <button
                                    className="btn-form"
                                    disabled={!acceptedTerms}
                                    onClick={() => props.acceptDisclaimer()}
                                >
                                    {t('Validate')}
                                </button>
                            </div>
                        </Box>
                }

            </StyledModal>
        </div>
    )
}
