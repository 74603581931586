import React, { useEffect } from 'react'
import { ReactComponent as MetamaskLogo } from "../assets/metamask.svg";
import { Tooltip } from "@mui/material";
import WithdrawModal from "./WithdrawModal";

import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { StackingAbi } from "../ABI/StackingAbi";
import { AppNetworkId, STAKING_CONTRACT_ADDRESS, TOKEN_NAME } from '../ABI/AppParams';
import InfoIcon from "@mui/icons-material/Info";
import { accountStakedTokensState, accountState, balanceVersionState, networkIdState } from '../atoms/atoms';
import { useRecoilState } from 'recoil'
import Web3 from 'web3';
import { useTranslation } from 'react-i18next';

export default function Withdraw() {

    const { t, i18n } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [account, setAccount] = useRecoilState(accountState)
    const [balanceVersion, setBalanceVersionState] = useRecoilState(balanceVersionState)
    const [networkId, setNetworkId] = useRecoilState(networkIdState)
    const [stakedAmount, setStakedAmount] = useRecoilState(accountStakedTokensState)



    const open = Boolean(anchorEl);

    useEffect(() => {
        fetchStakedTokens()
    }
    , [balanceVersion,account,networkId])

    const fetchStakedTokens = () => {
        try {

            if (window.ethereum && account != "" && account != null && networkId == AppNetworkId) {
                const web3 = new Web3(window.ethereum);
                const stakingContract = new web3.eth.Contract(
                    StackingAbi,
                    STAKING_CONTRACT_ADDRESS
                );
                stakingContract.methods
                    .userInfo(account)
                    .call().then(stackedResult => {
                        let stackedAmount = web3.utils.fromWei(stackedResult[0], "ether");
                        //let lpstack = Number(stackedAmount).toFixed(2);
                        //let lpstack = stackedAmount;
                        setStakedAmount(stackedAmount);
                        //setBalanceVersionState(balanceVersion + 1);
                    })
            }
        } catch (err) {
            console.error(err);
        }
    }

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleAddTokenToWallet = async (event) => {
        event.stopPropagation();
        const tokenAddress = STAKING_CONTRACT_ADDRESS;
        const tokenSymbol = TOKEN_NAME;
        const tokenDecimals = 18;
        //const tokenImage = base64AntWalletLogo;
        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await window.web3.currentProvider.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                        //image: tokenImage, // A string url of the token logo
                    },
                },
            });

            if (wasAdded) {
                console.log('token added!');
            } else {
                console.log('rejected!');
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div>
            <p className="ts-label"><Tooltip title="Show your participation in MetaMask" placement="right">
            <span onClick={handleAddTokenToWallet}>{t('Your staked amount')}</span> 
            </Tooltip> </p>
            <p className="ts-value">
                {stakedAmount!='--'?parseFloat(stakedAmount).toFixed(2):stakedAmount} <small>{TOKEN_NAME}</small>
            </p>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                <WithdrawModal
                    balance={stakedAmount}
                    account={account}
                />
                {/*<WithdrawModal
                        balance={store.userLpCakeStaked}
                        account={store.account}
              />*/}
                <p className="ts-label ts-labelb">

                    <Typography
                        aria-owns={open ? "mouse-over-popover1" : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                        style={{ display: "inline-block" }}

                    >
                        <InfoIcon style={{ paddingLeft: "0.2em" }} />
                    </Typography>
                    <Popover
                        id="mouse-over-popover1"
                        sx={{
                            pointerEvents: "none",
                        }}

                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                    >

                        <Typography className="typoinfo2" sx={{ p: 1 }}>
                            {/*<p style={{ textAlign: 'center', paddingBottom: 20 }}>{t('Recover the tokens that you have staked in your wallet')}</p>*/}
                            <h5 style={{ textAlign: 'center', paddingBottom: 20 }}>{t('Withdrawal fee')}</h5>

                            <p>{t('Note that withdrawal is not available until 4 months of staking')}</p>
                            <p>{t('Less than 6 months 6.00%')}</p>
                            <p>{t('Less than 9 months 5.00%')}</p>
                            <p>{t('Less than 12 months 4.00%')}</p>
                            <p>{t('12 months or more 0.00%')}</p>

                        </Typography>
                    </Popover>
                </p>

            </div>
        </div>
    )
}
