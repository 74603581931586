import MaterialTable from 'material-table';
import React, { useEffect, useState, forwardRef } from 'react';
import { BLOCK_EXPRORER_URL } from '../ABI/AppParams';
import { depositorsList, StakingClient } from '../services/graphql';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableColumnConfig = [
    {
        title: 'User',
        field: 'id',
        orderable: false,
        render: row => {
            let m = row.id && row.id.match && row.id.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
            return( 
                <div className="table-cell">
                    <div className="tx-field" data-toggle="tooltip" data-placement="top" title={row.id}>
                        <a target="_blank" rel="noopener noreferrer" href={`${BLOCK_EXPRORER_URL}address/${row.id}`}>
                            {m ? m[1] + '...' + m[3] : ''}
                        </a>
                    </div>
                </div>
                );
        }
    },
    {
        title: 'Amount',
        field: 'amount',
        render : row => {
            return(
                <div>{parseFloat(row.amount).toLocaleString()}</div>
            )
        }
    },
    {
        title: 'Withdraw Fees paid',
        field: 'totalPaidFees',
        render : row => {
            return(
                <div>{parseFloat(row.totalPaidFees).toLocaleString()}</div>
            )
        }
    },
    {
        title: 'total rewards claimed',
        field: 'totalRewardClaimed',
        render : row => {
            return(
                <div>{parseFloat(row.totalRewardClaimed).toLocaleString()}</div>
            )
        }
    }
    
]

const remoteData = (query) => {
    return StakingClient.query({
        query: depositorsList,
        variables: {
            offset: query.page * query.pageSize,
            limit: query.pageSize,
            orderBy: query.orderBy === undefined ? "amount" : query.orderBy.field,
            orderDirection: query.orderDirection === undefined || query.orderDirection === "" ? "desc" : query.orderDirection
        }
    }).then((res) => {
        setTimeout(() => {
            //window.$('div[data-toggle]').tooltip({});
        }, 1000);
        return {
            data: res.data.users,
            page: query.page,
            totalCount: res.data.poolInfos[0].userCount === undefined ? 0 : parseInt(res.data.poolInfos[0].userCount)
        }
    })
}

function StakingPool() {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, 1000);
    }, []);
    return (<>
        <h3 className="titleDash">Staking pool</h3>
        {
            visible ? (
                <MaterialTable
                    
                    icons={tableIcons}
                    columns={tableColumnConfig}
                    data={remoteData}
                 
                    options={{
                        toolbar: false,
                        showFirstLastPageButtons: false,
                        headerStyle: {
                            backgroundColor: '#e4ebf6',
                            color: '#000207'
                          }
                    
                       
                    }}

                />)
                : null
        }
    </>);
}

export default StakingPool;