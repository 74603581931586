import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { FaDiscord } from "react-icons/fa";
import { ReactComponent as Tradingview } from "../assets/tradingview.svg";

function Footer() {
  return (
    <div className="footer">
      {/*<div>
        <a
          href="https://swu-coin.com/"
          target="_blank"
          rel="noreferrer"
        >
          <Tradingview className="tv-icon" style={{ marginRight: "2px" }} />
        </a>
        <a
          href="https://twitter.com/"
          target="_blank"
          rel="noreferrer"
        >
          <TwitterIcon fontSize="large" />
        </a>
        <a
          href="https://www.youtube.com/"
          target="_blank"
          rel="noreferrer"
        >
          <YouTubeIcon fontSize="large" />
        </a>
        <a
          href="https://discord.com/"
          target="_blank"
          rel="noreferrer"
        >
          <FaDiscord size="2em" />
        </a>
  </div>*/}
      <p>
        Copyright &copy; 2022
        <a href="https://swu-coin.com/"> SWU</a> All rights reserved
      </p>
    </div>
  );
}

export default Footer;
