import React from 'react'
import Apy from '../components/Apy'
import Logo from "../assets/logo.png"
import ClaimRewards from '../components/ClaimRewards'
import FarminigMultiplier from '../components/FarminigMultiplier'
import PoolTotalStaked from '../components/PoolTotalStaked'
//import ProfileCard from '../components/ProfileCard'
import TokenBalance from '../components/TokenBalance'
import Withdraw from '../components/Withdraw'
import DepositModal from "../components/DepositModal";
export default function Staking() {
  return (

      <div className="card">
      <div>  
      <div className="card-title">
        <img
            
            src={Logo}
            alt="swu logo"
            width="120"
            
         
          />
          <h1>Staking</h1>
          </div>
          </div> 
    


      <div>
      <div className="alignDiv">
        <div className="div1 centerBlo">
        <PoolTotalStaked></PoolTotalStaked>
        <FarminigMultiplier></FarminigMultiplier>
        <Apy></Apy>
        
        </div>
        </div>
       
     
      
        <DepositModal />
      
      
       
        <div className="centerBloc3"> 
        <TokenBalance></TokenBalance>
        <ClaimRewards></ClaimRewards>
        <Withdraw></Withdraw>
        </div>
        
      

        </div>

   {/* 
   
    <div>
        <div className="centerBlo"> 
        <TokenBalance></TokenBalance>
        <PoolTotalStaked></PoolTotalStaked>
        </div>
        <div className="centerBlo2">
        <ClaimRewards></ClaimRewards>
      
        <Withdraw></Withdraw>
        <Apy></Apy>
        <FarminigMultiplier></FarminigMultiplier>
        </div>
        <DepositModal/>

        </div>
   */}

      </div>

     
  )
}
