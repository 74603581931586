import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { useRecoilState } from 'recoil'
import Web3 from 'web3';
import { RPC_URL, STAKING_CONTRACT_ADDRESS, TOKEN_NAME } from '../ABI/AppParams';
import { StackingAbi } from '../ABI/StackingAbi';
import { rewardTokenPerBlockState } from '../atoms/atoms';

export default function FarminigMultiplier() {

    const { t, i18n } = useTranslation();

    useEffect(() => {
        fetchStakingRewardRate();
      }, []);

    const fetchStakingRewardRate = () => {

        const web3 = new Web3(RPC_URL);

        const contract = new web3.eth.Contract(
            StackingAbi,
            STAKING_CONTRACT_ADDRESS
        );
        contract.methods
            .SWUPerBlock()
            .call().then(result => {
                setStakingRewardRate(web3.utils.fromWei(result, "ether"));
            });
    };



    const [stakingRewardRate, setStakingRewardRate] = useRecoilState(rewardTokenPerBlockState);
    return (
        <div>
            <p className="ts-label">
            {t('Pool profit')} <span className="ts-value">{stakingRewardRate == "--" ? "--" : (stakingRewardRate).toLocaleString()}<span className="ts-value small"> {TOKEN_NAME} / Bloc </span></span>
            </p>
            

        </div>
    )
}
