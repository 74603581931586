import { atom } from 'recoil'

export const accountState = atom({
  key: 'accountState',
  default: '',
})
export const networkIdState = atom({
  key: 'networkIdState',
  default: '',
})
export const balanceVersionState = atom({
  key: 'balanceVersionState',
  default: 0,
})
export const walletBalanceState = atom({
  key: 'walletBalanceState',
  default: '',
})
export const accountStakedTokensState = atom({
  key: 'accountStakedTokensState',
  default: '--',
})
export const isOwnerState = atom({
  key: 'isOwnerState',
  default: false,
})

export const poolTotalStakedState = atom({
  key: 'poolTotalStakedState',
  default: "--",
})

export const pendingRewardVersionState = atom({
  key: 'pendingRewardVersionState',
  default: 0,
})

export const rewardTokenPerBlockState = atom({
  key: 'rewardTokenPerBlockState',
  default: "--",
})