import {React, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { Link , useNavigate } from "react-router-dom";



export default function LanguageSelector() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    
    
    useEffect(()=>{
        getLangFromRedirectUrl();
    },[])

    const getLangFromRedirectUrl = ()=>{ 
        const searchParams = new URLSearchParams(window.location.search);
        const lang = searchParams.get('lang');
        if(lang){
            i18n.changeLanguage(lang);
            localStorage.setItem("lang", lang);
            navigate('/')
        }
    }

    const changeLang = (lang) => (e) => {
        e.preventDefault();
        i18n.changeLanguage(lang);
        localStorage.setItem("lang", lang);
        return false;
    };


    return (
        <>
            <div className="languages-list">
                <li>
                    <span onClick={changeLang("fr")}>
                        <img
                            style={{ height: 24 }}
                            src="/images/fr.png"
                            alt="fr"
                        />
                    </span>
                </li>
                <li>
                    <span onClick={changeLang("es")}>
                        <img
                            style={{ height: 24 }}
                            src="/images/es.png"
                            alt="es"
                        />
                    </span>
                </li>
                <li>
                    <span onClick={changeLang("en")}>
                        <img
                            style={{ height: 24 }}
                            src="/images/en.png"
                            alt="en"
                        />
                    </span>
                </li>
            </div>
        </>
    )
}
