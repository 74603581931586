import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import Box from "@mui/material/Box";
import logoE from "../assets/curs2.png"
import { useTranslation } from "react-i18next";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.95);
  -webkit-tap-highlight-color: transparent;
`;

export default function AlertModal(props) {

    const { t, i18n } = useTranslation();

    useEffect(() => {
    }, [props.open])

    return (
        <div>

            <StyledModal
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                id="add-modal"
                open={props.open}
                onClose={props.onClose}
                BackdropComponent={Backdrop}
            >


                <Box>
                    <div className="alignWith">
                        <h2 id="modal-title-withdraw"></h2>
                        <button onClick={props.onClose} className="btnClose">x</button>
                    </div>

                    <div className="box-container" style={{ padding: "0px" }}>
                        <div style={{textAlign:'center'}}>
                            <p>{props.message}</p>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                        <button
                            className="btn-form"
                            onClick={props.onClose}
                        >
                            {t('Close')}
                        </button>
                    </div>
                </Box>

            </StyledModal>
        </div>
    )
}
