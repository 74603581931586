import React, { useEffect, useState } from 'react'

import { useRecoilState } from 'recoil'
import { accountState, networkIdState, pendingRewardVersionState  } from '../atoms/atoms';
import Web3 from 'web3';
import { AppNetworkId, COMINIG_SOON_ACTIVATED, STAKING_CONTRACT_ADDRESS , TOKEN_NAME} from '../ABI/AppParams';
import { StackingAbi } from '../ABI/StackingAbi';
import { useTranslation } from 'react-i18next';

export default function ClaimRewards() {

    const { t, i18n } = useTranslation();

    const [pending, setPending] = useState("0");
    const [account, setAccount] = useRecoilState(accountState)
    const [networkId, setNetworkId] = useRecoilState(networkIdState)
    const [pendingRewardVersion, setPendingRewardVersion] = useRecoilState(pendingRewardVersionState)

    useEffect(() => {
        pendingReward();
    }, [account, networkId, pendingRewardVersion])

    async function handleClaim() {
        try {
            if (window.ethereum && account != "" && account != null && networkId == AppNetworkId) {
                const web3 = new Web3(window.ethereum);
                const stakingContract = new web3.eth.Contract(
                    StackingAbi,
                    STAKING_CONTRACT_ADDRESS
                );
                const claimResult = await stakingContract.methods
                    .claim()
                    .send({ from: account });
                    setPendingRewardVersion((pendingRewardVersion+1))
                console.log(claimResult);
            }
        } catch (err) {
            console.error(err);
        }
    }


    const pendingReward = async () => {

        if (window.ethereum && account != "" && account != null && networkId == AppNetworkId) {

            const web3 = new Web3(window.ethereum);
            const stakingContract = new web3.eth.Contract(
                StackingAbi,
                STAKING_CONTRACT_ADDRESS
            );
            const resultReward = await stakingContract.methods
                .pendingSWU(account)
                .call();
            let rewardAmount = web3.utils.fromWei(resultReward, "ether");
            //let reward = Number(rewardAmount).toFixed(4).toLocaleString();
            setPending(rewardAmount);


            //web3.eth.getBlockNumber().then(console.log);
        } else {
            setPending("--");
        }


    };
    return (
        <div>
            <p className="ts-label">{t('SWU earned - Available')}</p>
           
            <p className="ts-value">{pending !='--' ? parseFloat(pending).toFixed(4) : pending} <small>{TOKEN_NAME}</small></p>
            <button className="ts-btn-s" onClick={handleClaim} disabled={account == "" || account == null || COMINIG_SOON_ACTIVATED}>
                {t('Claim')}
            </button>
            {/*parseFloat(store.availableAmoutToClaim) < parseFloat(pending) ?
                  <button className="ts-btn-s" disabled="true">
                    Insufficient balance for claim
                  </button>
                  :
                  <button className="ts-btn-s" onClick={handleClaim} disabled={store.account == "" || store.account == null}>
                    Claim
                  </button>
                }*/}
        </div>
    )
}
