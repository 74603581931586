import MaterialTable from 'material-table';
import React, { useEffect, useState, forwardRef } from 'react';
import { BLOCK_EXPRORER_URL } from '../ABI/AppParams';
import { StakingClient, stakingTransacctionsList } from '../services/graphql';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import moment from 'moment';


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableColumnConfig = [
    //{
    // title: 'Transaction',
    //field: 'transaction',
    //render: row => {
    // let m = row.transaction && row.transaction.match && row.transaction.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
    //return <div className="tx-field" data-toggle="tooltip" data-placement="top" title={row.transaction}>{m ? m[1] + '...' + m[3] : ''}</div>;
    //return <div className="tx-field-tables tx-field" data-toggle="tooltip" data-placement="top" titre={row.transaction} ><a target="_blank" rel="noopener noreferrer" href={`${blockExplorerUrl}/tx/${row.transaction}`}>{m ? m[1] + '...' + m[3] : ''}</a></div>
    //}
    //},
    {
        title: 'Transaction hash',
        field: 'hash',
        render: row => {
            let m = row.hash && row.hash.match && row.hash.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
            return (
                <div className="table-cell">
                    <div className="tx-field" data-toggle="tooltip" data-placement="top" title={row.hash}>
                        <a target="_blank" rel="noopener noreferrer" href={`${BLOCK_EXPRORER_URL}tx/${row.hash}`}>
                            {m ? m[1] + '...' + m[3] : ''}
                        </a>
                    </div>
                </div>)
            //return <div className="tx-field-tables tx-field" data-toggle="tooltip" data-placement="top" titre={row.sender} ><a target="_blank" rel="noopener noreferrer" href={`${BLOCK_EXPRORER_URL}/address/${row.sender}`}>{m ? m[1] + '...' + m[3] : ''}</a></div>
        }
    },
    {
        title: 'User',
        field: 'userAddress',
        orderable: false,
        render: row => {
            let m = row.userAddress && row.userAddress.match && row.userAddress.match(/^([\s\S]{5})([\s\S]*?)([\s\S]{7})$/i);
            return( 
                <div className="table-cell">
                    <div className="tx-field" data-toggle="tooltip" data-placement="top" title={row.userAddress}>
                        <a target="_blank" rel="noopener noreferrer" href={`${BLOCK_EXPRORER_URL}address/${row.userAddress}`}>
                            {m ? m[1] + '...' + m[3] : ''}
                        </a>
                    </div>
                </div>
                );
        }
    },
    {
        title: 'Amount',
        field: 'amount',
        render : row => {
            return(
                <div>{parseFloat(row.amount).toLocaleString()}</div>
            )
        }
    },
    {
        title: 'Withdraw Fees',
        field: 'amountToFunding',
        render : row => {
            return(
                <div>{parseFloat(row.amountToFunding).toLocaleString()}</div>
            )
        }
    },
    {
        title: 'Type',
        field: 'type'
    },
    {
        title: 'Timestamp',
        field: 'timestamp',
        render: row => {
            moment(Number.parseInt(row["timestamp"])).format('DD/MM/YYYY HH:mm:ss')
                if(row["timestamp"] == null){
                    return "";
                }
                var a = new Date(row["timestamp"]* 1000);
                var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                var year = a.getFullYear();
                var month = months[a.getMonth()];
                var date = a.getDate();
                var hour = a.getHours();
                var min = a.getMinutes();
                //var sec = a.getSeconds();
                var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min ;//+ ':' + sec ;
                return time;
                
        }
    }
]

const remoteData = (query) => {
    return StakingClient.query({
        query: stakingTransacctionsList,
        fetchPolicy: 'network-only',
        variables: {
            offset: query.page * query.pageSize,
            limit: query.pageSize,
            orderBy: query.orderBy === undefined ? "timestamp" : query.orderBy.field,
            orderDirection: query.orderDirection === undefined || query.orderDirection === "" ? "desc" : query.orderDirection
        }
    }).then((res) => {
        setTimeout(() => {
            //window.$('div[data-toggle]').tooltip({});
        }, 1000);
        return {
            data: res.data.transactions,
            page: query.page,
            totalCount: res.data.poolInfos[0].totalTransactionCount === undefined ? 0 : parseInt(res.data.poolInfos[0].totalTransactionCount)
        }
    })
}

function StakingTransactionsList() {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, 1000);
    }, []);
    return (<>
        <h3 className="titleDash">Transaction list</h3>
        {
            visible ? (
                <MaterialTable
                    
                    icons={tableIcons}
                    columns={tableColumnConfig}
                    data={remoteData}
                    options={{
                        toolbar: false,
                        showFirstLastPageButtons: false,
                        headerStyle: {
                            backgroundColor: '#e4ebf6',
                            color: '#000207'
                          }
                    }}

                />)
                : null
        }
    </>);
}

export default StakingTransactionsList;