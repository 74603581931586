import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import { COMINIG_SOON_ACTIVATED, STAKING_CONTRACT_ADDRESS, TOKEN_NAME } from "../ABI/AppParams";
import { StackingAbi } from "../ABI/StackingAbi";
import Web3 from "web3";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css'
import RangeSlider from 'react-bootstrap-range-slider'
import {
  Row,
  Col,
  DropdownButton,
  Dropdown,
  Form,
  Button
} from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled("div")`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.95);
  -webkit-tap-highlight-color: transparent;
`;

export default function WithdrawModal({ account, balance, handleClaim }) {

  const { t, i18n } = useTranslation();

  const [withdrawInput, setWithdrawInput] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    verifyCurrentMonth();
    setAmountToReceive(0);
    setAmountToFunding(0);
    setClaimBenefitsChecked(false);
    setWithdrawInput("");
  }
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);
  const [claimBenefitsChecked, setClaimBenefitsChecked] = useState(false);

  const [amountToReceive, setAmountToReceive] = useState(0);
  const [amountToFunding, setAmountToFunding] = useState(0);
  const [currentWithdrawFeesMonth, setCurrentWithdrawFeesMonth] = useState(0);
  const [currentWithdrawFeesRate, setCurrentWithdrawFeesRate] = useState(0);

  const handleMax = (e) => {
    try {
      if (balance == "--") {
        setWithdrawInput('0');
        onChange({ target: { value: '0' } })
      } else {
        setWithdrawInput(`${balance.replace(",", ".")}` || '0');
        onChange({ target: { value: `${balance.replace(",", ".")}` || '0' } })
      }
    } catch (e) {
      console.error(e)
      setWithdrawInput('0')
      onChange('0')
    }
  };

  useEffect(() => {
    simulateWithdraw();
    return () => {

    }
  }, [withdrawInput])

  const simulateWithdraw = () => {

    if (withdrawInput == "") {
      setAmountToFunding(0);
      setAmountToReceive(0);
      return;
    }
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(
      StackingAbi,
      STAKING_CONTRACT_ADDRESS
    );
    let amount = web3.utils.toWei(withdrawInput.replace(",", "."), "ether");
    contract.methods
      .withdrawFees(account, amount)
      .call().then(result => {
        try {
          setAmountToFunding(web3.utils.fromWei(result["tax"], "ether"));
          setAmountToReceive(web3.utils.fromWei(result["value"], "ether"));
        } catch (e) {

        }
      });

  };

  const onChange = (event) => {
    if (parseFloat(event.target.value) < 0) {
      setWithdrawInput('0')
    } else {
      setWithdrawInput(event.target.value);
    }
  };

  const handleClaimBenefits = (event) => {
    setClaimBenefitsChecked(event.target.checked);
  }


  const verifyCurrentMonth = () => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(
      StackingAbi,
      STAKING_CONTRACT_ADDRESS
    );
    if (account != null && account != "") {

      contract.methods
        .currentWithdrawFees(account)
        .call().then(result => {
          setCurrentWithdrawFeesMonth(result["0"])
          setCurrentWithdrawFeesRate(result["1"])

        });
    }
  }

  async function handleWithdraw() {
    setIsLoading(true);
    try {
      const web3 = new Web3(window.ethereum);
      let amount = web3.utils.toWei(withdrawInput, "ether");

      const contract = new web3.eth.Contract(
        StackingAbi,
        STAKING_CONTRACT_ADDRESS
      );

      const withdrawResult = await contract.methods
        .withdraw(amount, claimBenefitsChecked)
        .send({ from: account });
      // handleClaim();
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
    handleClose();
  }

  return (
    <div>
      <button className="ts-btn-s2" onClick={handleOpen} disabled={!account || COMINIG_SOON_ACTIVATED}>
        {t('Withdraw')}
      </button>
      <StyledModal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        id="add-modal"
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
      >
        {!isLoading ? (
          <Box>
            <div className="alignWith">
              <h2 id="modal-title-withdraw">{t('Withdraw')} {TOKEN_NAME}</h2>
              <button onClick={handleClose} className="btnClose">x</button>
            </div>


            <div className="box-container">
              <div>
                <label id="modal-description">
                  {TOKEN_NAME} {t('staked')} : <span>{balance}</span>
                </label>
                <div className="modalInp">
                  {/* <Input
                    placeholder="0"
                    value={withdrawInput.replace(",", ".")}
                    onChange={onChange}
                    type="number"
                  /> */}

                  <label>{t('Choose the amount you want to withdraw')}</label>
                  <div>
                    <Form.Group className="posiCoin22">
                      <div>
                        <RangeSlider
                          value={withdrawInput}
                          variant="light"
                          tooltip="off"
                          size="sm"
                          onChange={e => setWithdrawInput(e.target.value)}
                          className="RangeS"
                          max={balance}
                        />
                      </div>
                      <div>
                        <Input value={withdrawInput} onChange={e => setWithdrawInput(e.target.value)} size='sm' />
                      </div>

                      <div>
                        <label className="modal-coin">{TOKEN_NAME}</label>
                      </div>
                    </Form.Group>

                  </div>

                  {/* <button className="max-btn" onClick={handleMax}>
                    MAX
                  </button> */}
                </div>
                {parseFloat(withdrawInput) > balance && withdrawInput > 0 ? (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">
                      {t('This amount is not available!')}
                    </Alert>
                  </Stack>
                ) :
                  <div style={{ display: 'block',textAlign: 'center' }}>
                    {currentWithdrawFeesMonth > 0 ? currentWithdrawFeesMonth == 4? t('Withdrawal not available until 4 months of staking') :currentWithdrawFeesMonth == 13? t('* You have more than 12 Months of staking, the fees applied will be 0%'):<label style={{ color: "#e4ebf6", padding: "10px 10px 0px 5px", fontSize: "11px" }} >* {t('You have less than')} {currentWithdrawFeesMonth} {t('Months of staking, the fees applied will be')} {currentWithdrawFeesRate}%</label> : null}
                    <br/>
                    {amountToFunding > 0? <label style={{ color: "#e4ebf6", padding: "10px 10px 0px 5px", fontSize: "11px" }} >* {t('Funding fees')} {parseFloat(amountToFunding)} {TOKEN_NAME}</label> : null}
                    <br/>
                    {amountToReceive > 0 ? <label style={{ color: "#e4ebf6", padding: "10px 10px 0px 5px" }}>{t('You will recieve')} {parseFloat(amountToReceive)} {TOKEN_NAME}</label> : null}
                  </div>
                }

                <Box component="span" className="claim-box">
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={claimBenefitsChecked} onChange={handleClaimBenefits} />} label={t("Claim benefits")} />
                  </FormGroup>
                </Box>
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center' }}>
                  <button
                    className="btn-form"
                    disabled={currentWithdrawFeesMonth == 4 || parseFloat(withdrawInput) <= 0 || withdrawInput == "" || parseFloat(withdrawInput) > balance}
                    onClick={() => handleWithdraw()}
                  >
                    {t('Validate')}
                  </button>
                </div>
              </div>
            </div>
          </Box>
        ) : (
          <Box style={{textAlign:'center'}}>
            <h2 id="modal-title">{t('Withdraw')} {TOKEN_NAME}</h2>
            <div className="box-container">
              <h2 id="modal-description">{t('Pending confirmation')}</h2>
              <p id="modal-description">
                {t('Validate this transaction in your wallet')}
              </p>
              <button className="btn-form">
                <CircularProgress className="cercleS" />
              </button>
            </div>
          </Box>
        )}
      </StyledModal>
    </div>
  );
}
